import React, { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  Box,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import { Check, Cancel, PhotoCamera } from '@material-ui/icons';
import moment from 'moment';
import useSound from 'use-sound';
import { CustomImgCard } from '../../../custom';
import { alert, businesses, tickets } from '../../../../state';
import CustomInputField from '../../../custom/CustomInputField';
import CheckInScanner from '../../../custom/CheckInScanner';
import beep1 from '../../../../sounds/beep-1.mp3';
import beep7 from '../../../../sounds/beep-7.mp3';

const CheckInForm = () => {
  const { t } = useTranslation();
  const [successSound] = useSound(beep7);
  const [failedSound] = useSound(beep1);
  const [showScanner, setShowScanner] = useState(false);
  const business = useSelector(businesses.selectors.selectBusiness);
  const isLoading = useSelector(businesses.selectors.selectIsLoading);
  const ticketEvent = useSelector(tickets.selectors.selectTicketEvent);
  const [ticketInfo, setTicketInfo] = useState({});
  const dispatch = useDispatch();
  const openAlert = (payload) => dispatch(alert.actions.open(payload));

  useEffect(() => {}, [business]);
  return (
    <Box
      borderRadius={20}
      border="1px solid #ccc"
      p={4}
      mb={4}
      mt={4}
      bgcolor={business.brandColors?.formColor || '#fff'}
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          container
          item
          xs={12}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <Box pt={2} display="block" align="center">
              <Typography variant="h5" color="primary">
                {isLoading ? <Skeleton width="190px" /> : business.businessName}
              </Typography>
            </Box>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <Box py={2}>
                <CustomImgCard
                  isLoading={isLoading}
                  name={ticketEvent.title}
                  width="270px"
                  imageUrl={ticketEvent.imageUrl}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box py={2}>
                {/* eslint-disable-next-line no-nested-ternary */}
                {business.tier ? (
                  <>
                    <Box mt={1} textAlign="center">
                      <Check style={{ color: 'green', fontSize: '150px' }} />
                    </Box>
                    <Box fontWeight={700} mb={3}>
                      <Typography display="block" align="center" variant="h4">
                        You are checked in!
                      </Typography>
                      <Typography display="block" align="center" variant="h5">
                        {business.firstName} {business.lastName}
                      </Typography>
                      <Typography display="block" align="center" variant="h5">
                        {moment(business.checkInTime).format(
                          'MMMM DD, YYYY, h:mma'
                        )}
                      </Typography>
                    </Box>
                  </>
                ) : business.error ? (
                  <Box color="warning">
                    <Box mt={1} textAlign="center">
                      <Cancel style={{ color: 'red', fontSize: '150px' }} />
                    </Box>
                    <Box fontWeight={700} mb={3}>
                      <Typography display="block" align="center" variant="h5">
                        Sorry we could not find your ticket information.
                      </Typography>
                      <Typography display="block" align="center" variant="h5">
                        Please contact the business team.
                      </Typography>
                    </Box>
                  </Box>
                ) : (
                  <>
                    <Typography
                      display="block"
                      align="center"
                      variant="h5"
                      style={{ fontFamily: 'narcost-regular' }}
                    >
                      {ticketEvent.title} Check-In
                    </Typography>
                    <Typography
                      display="block"
                      align="center"
                      variant="button"
                      style={{ fontFamily: 'gilmer-bold' }}
                    >
                      {moment(`${ticketEvent.startTime}Z`).format(
                        'MMM D, YYYY h:mm A'
                      )}
                    </Typography>
                    <Formik
                      enableReinitialize
                      validationSchema={() =>
                        Yup.object().shape({
                          code: Yup.string().required('Code is required'),
                        })
                      }
                      initialValues={{
                        code: '',
                      }}
                      onSubmit={async ({ code }) => {
                        setTicketInfo({});
                        const payload = {
                          code,
                          id: ticketEvent.id,
                        };
                        const res = await dispatch(
                          tickets.actions.checkIn(payload)
                        );
                        if (!res.error) {
                          if (!res.payload.checkedIn) {
                            successSound();
                            setTicketInfo(res.payload);
                          } else {
                            openAlert({
                              message: 'Ticket was checked in before',
                              severity: 'error',
                            });
                          }
                        } else {
                          failedSound();
                          openAlert({
                            message:
                              res.error?.message || 'Something went wrong...',
                            severity: 'error',
                          });
                        }
                      }}
                    >
                      {({ isSubmitting, handleSubmit, setFieldValue }) => {
                        const setCode = (val) => {
                          if (val) {
                            setFieldValue('code', val);
                            setShowScanner(false);
                            handleSubmit();
                          }
                        };
                        return (
                          <Form>
                            <Box mt={3}>
                              <Grid container justifyContent="space-between">
                                <Grid
                                  item
                                  style={{ width: 'calc(100% - 90px)' }}
                                >
                                  <CustomInputField
                                    customInput={Field}
                                    component={TextField}
                                    fullWidth
                                    autoFocus
                                    name="code"
                                    variant="outlined"
                                  />
                                </Grid>
                                <Grid
                                  item
                                  alignItems="flex-end"
                                  textAlign="center"
                                >
                                  <Button
                                    variant="outlined"
                                    onClick={() => setShowScanner(true)}
                                    style={{ padding: 10 }}
                                  >
                                    <PhotoCamera />
                                  </Button>
                                </Grid>
                              </Grid>
                            </Box>
                            {ticketInfo?.id ? (
                              <Box my={3}>
                                <Typography>
                                  Check in ticket information:
                                </Typography>
                                <Typography>
                                  First Name: {ticketInfo.firstName}
                                </Typography>
                                <Typography>
                                  Last Name: {ticketInfo.lastName}
                                </Typography>
                                <Typography>
                                  Check In Time:{' '}
                                  {moment(`${ticketInfo.checkInAt}`).format(
                                    'MMMM DD, YYYY HH:mm:ss'
                                  )}
                                </Typography>
                              </Box>
                            ) : null}
                            <Box
                              my={3}
                              display="flex"
                              alignItems="center"
                              flexDirection="column"
                              alignContent="center"
                            >
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                                type="submit"
                                disabled={isSubmitting}
                              >
                                {isSubmitting ? (
                                  <CircularProgress size={24} />
                                ) : (
                                  t('checkIn')
                                )}
                              </Button>
                            </Box>
                            <CheckInScanner
                              setCode={setCode}
                              open={showScanner}
                              close={() => setShowScanner(false)}
                            />
                          </Form>
                        );
                      }}
                    </Formik>
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CheckInForm;
